:root {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #6a8d73;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  /* --rdp-outline: 2px solid var(--rdp-accent-color); */
  --rdp-outline: 1px solid #37493b;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 1px solid #37493b;
}

.rdp {
  margin: 0;
}